import React, { FC } from "react";
import { handleKeyboardPressWithEvent } from "utils/accessibility";
import memoize from "utils/memoize";

export interface RHUnderlineProps {
  onClick?: (e?: React.MouseEvent) => void;
  style?: React.CSSProperties;
  className?: string;
  disabled?: boolean;
  id?: string;
  "data-testid"?: string;
  role?: string;
  tabIndex?: number;
}

export const RHUnderline: FC<RHUnderlineProps> = props => {
  const { onClick, children, style, className, disabled, id, ...rest } = props;
  return (
    <span
      onClick={e => {
        e?.stopPropagation();
        if (onClick && !disabled) onClick();
      }}
      id={id}
      data-testid={props?.["data-testid"]}
      style={{
        textDecoration: "underline",
        cursor: !!onClick ? (disabled ? "not-allowed" : "pointer") : "inherit",

        ...(style ? { ...style } : null)
      }}
      className={className}
      tabIndex={onClick && !disabled ? 0 : -1}
      onKeyDown={
        onClick && !disabled ? handleKeyboardPressWithEvent(onClick) : undefined
      }
      {...rest}
    >
      {children}
    </span>
  );
};

export default memoize(RHUnderline);
